// General
import React, { useEffect, useState } from "react";
// Styles
import "./imageGallery.module.css";
// Components
import { DMSImageGalleryData, ImageGallery as DMSImageGallery } from "dms-lib";
// Model
import {
  FieldPhotoStatus,
  InfoFieldPhoto,
  isMediaAvailable,
  isMediaWaiting,
  MediaFileStatus,
  MediaInfo,
  useI18n,
} from "compass-commons";
import TimelineService from "../../../services/TimelineService";
import { InfoFieldPhotoShort } from "../../../models/incidents/media/InfoFieldPhotoShort";
import GetMediaFileError from "../../../errors/GetMediaFileError";
// Helpers
import { buildCaption } from "../../../helpers/captionHelper";
import { MediaDataMap } from "../../../models/incidents/media/MediaData";

interface ImageGalleryProps {
  isOpen: boolean;
  onClose: () => void;
  mediaData?: MediaDataMap;
}

type DMSImageGalleryMap = {
  [key: string]: DMSImageGalleryData;
};

const ImageGallery = (props: ImageGalleryProps): JSX.Element => {
  const { t } = useI18n();
  const { isOpen, onClose, mediaData = {} } = props;

  const checkErrorStatus = (mediaFileStatus: MediaFileStatus) =>
    !isMediaAvailable(mediaFileStatus) && !isMediaWaiting(mediaFileStatus);

  const checkIsRequested = (fieldPhotoStatus: FieldPhotoStatus) =>
    fieldPhotoStatus !== FieldPhotoStatus.NOT_REQUESTED;

  const convertToDMSImageGalleryData = (
    src: string,
    hasError: boolean,
    infoFieldPhotoShort: InfoFieldPhotoShort,
    isLoading: boolean,
    isRequested?: boolean,
    canRequest?: boolean,
    placeholderText?: string
  ): DMSImageGalleryData => ({
    src,
    hasError,
    isLoading,
    isRequested,
    canRequest,
    placeholderText,
    caption: buildCaption(infoFieldPhotoShort, "\n"),
  });

  const mapToDMSImageGalleryData = (
    currentValue: InfoFieldPhoto,
    fileId: string,
    loading: boolean,
    mediaContent = "",
    mediaDetail?: MediaInfo
  ) =>
    convertToDMSImageGalleryData(
      mediaContent,
      !currentValue?.fileId ||
        (!!mediaDetail?.mediaFileStatus &&
          checkErrorStatus(mediaDetail.mediaFileStatus)),
      {
        fileId,
        mediaTimestamp:
          currentValue.mediaFile?.mediaTimestamp ||
          currentValue.desiredTimestamp,
        resourceMappingName: currentValue.resourceMapping?.name,
      },
      loading,
      checkIsRequested(currentValue.fieldPhotoStatus),
      false,
      t("snapshots.notRequested")
    );

  const buildImageMap = (mediaDataMap: MediaDataMap): DMSImageGalleryMap =>
    Object.entries(mediaDataMap).reduce(
      (
        previousValue: DMSImageGalleryMap,
        [key, currentValue]: [string, InfoFieldPhoto]
      ) => ({
        ...previousValue,
        [key]: mapToDMSImageGalleryData(currentValue, key, true),
      }),
      {}
    );

  const [imagesToDisplayMap, setImagesToDisplayMap] =
    useState<DMSImageGalleryMap>(buildImageMap(mediaData));

  const getMediaFile = async (mediaId) => {
    const media = await TimelineService.getMediaById(mediaId)
      .then((res: MediaInfo) => {
        return res;
      })
      .catch((error) => {
        console.log(error);
        throw new GetMediaFileError(mediaId);
      });

    return media;
  };

  // eslint-disable-next-line consistent-return
  const loadImage = async (fileId: string = null): Promise<any> => {
    try {
      const mediaId = fileId;
      const content = await TimelineService.getMediaContentById(mediaId);
      if (content) {
        const imageBlob = URL.createObjectURL(content);
        return imageBlob;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDetails = async (ifp: InfoFieldPhoto, key: string) => {
    let mediaDetail: MediaInfo;
    let mediaContent = "";
    try {
      if (ifp?.fileId && checkIsRequested(ifp.fieldPhotoStatus)) {
        mediaDetail = await getMediaFile(ifp?.fileId);
        if (!checkErrorStatus(mediaDetail?.mediaFileStatus)) {
          mediaContent = await loadImage(mediaDetail.id);
        }
      }
    } catch (error) {
      console.log(error);
    }
    setImagesToDisplayMap((previousValue) => ({
      ...previousValue,
      [key]: mapToDMSImageGalleryData(
        ifp,
        key,
        false,
        mediaContent,
        mediaDetail
      ),
    }));
  };

  useEffect(() => {
    Object.entries(mediaData).forEach(([key, currentValue]) => {
      fetchDetails(currentValue, key);
    });
    return () =>
      Object.keys(imagesToDisplayMap).forEach((key) =>
        URL.revokeObjectURL(imagesToDisplayMap[key].src)
      );
  }, []);

  return (
    <DMSImageGallery
      imagesData={imagesToDisplayMap}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

export default ImageGallery;
